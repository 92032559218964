import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { GlobalProvider } from './hooks/useGlobal';
import { WebSocketProvider } from './hooks/useSocket';
import './assets/css/style.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Suspense fallback="loading">
    <GlobalProvider>
      <WebSocketProvider>
        <App />
      </WebSocketProvider>
    </GlobalProvider>
  </Suspense>,
);
