import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { CustomFlowbiteTheme, Flowbite } from 'flowbite-react';

const flowbiteTheme: CustomFlowbiteTheme = {
  card: {
    root: {
      base: 'flex rounded-lg border border-gray-200 bg-white shadow-sm',
      children: 'flex h-full flex-col justify-center p-4',
    },
  },
};

function App() {
  return (
    <BrowserRouter>
      <Flowbite theme={{ theme: flowbiteTheme }}>
        <Router />
      </Flowbite>
    </BrowserRouter>
  );
}

export default App;
