import React, { FC, useState } from 'react';
import { Button } from 'flowbite-react';
import { DateRangePicker, Range } from 'react-date-range';

export interface DatePickerRange extends Range {}

interface CalendarModalProps {
  ranges: Range[];
  onChange: (ranges: any) => void;
}

const CalendarModal: FC<CalendarModalProps> = ({
  ranges,
  onChange,
}: CalendarModalProps) => {
  return (
    <div className="absolute left-0 z-50">
      <div className="rounded-md border p-4 shadow-md outline-none bg-white">
        <DateRangePicker
          ranges={ranges}
          onChange={onChange}
          maxDate={new Date()}
          showMonthArrow={true}
          showMonthAndYearPickers={false}
          weekStartsOn={1}
          dragSelectionEnabled={false}
          showDateDisplay={false}
          inputRanges={[]}
        />
      </div>
    </div>
  );
};

interface DatePickerProps {
  open: boolean;
  onClose: () => void;
  ranges: Range[];
  onChange: (e: any) => void;
}

export const DatePicker: FC<DatePickerProps> = ({
  ranges,
  onChange,
  open = false,
  onClose,
}: DatePickerProps) => {
  return (
    <div className="relative">
      <Button size="sm" onClick={onClose}>
        16.11.2023
      </Button>

      {open && <CalendarModal ranges={ranges} onChange={onChange} />}
    </div>
  );
};
