import { FC } from 'react';

export const Footer: FC = () => {
  return (
    <footer className="my-4 text-center">
      <p className="text-xs text-gray-400">
        v1.0 - {process.env.REACT_APP_VERSION_CHECK}
      </p>
    </footer>
  );
};
