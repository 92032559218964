import { ReactNode } from 'react';
import { Card } from 'flowbite-react';

interface ICardContainer {
  title?: string;
  action?: () => ReactNode | null;
  children?: ReactNode;
}

export const CardContainer = ({ title, children, action }: ICardContainer) => {
  return (
    <Card>
      <div className="flex justify-between items-center">
        {title && <h3 className="text-xl font-bold text-gray-900">{title}</h3>}
        {action && <div>{action()}</div>}
      </div>
      {children && <div className="mt-4">{children}</div>}
    </Card>
  );
};
