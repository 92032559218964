import { FC, useState, useCallback, useEffect } from 'react';
import { Button, Spinner, Table } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { CardContainer } from '../../layout';
import { baseURL, getSites } from '../../services';
import { HiPencil } from 'react-icons/hi';

export const SiteMain: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sites, setSites] = useState<any[]>([]);

  const fetchData = useCallback(async () => {
    try {
      const response: any = await getSites();
      setSites(response.data);
    } catch (error) {
      console.error('getSites error:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CardContainer title="Siteler">
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <Table striped hoverable>
          <Table.Head>
            <Table.HeadCell>#</Table.HeadCell>
            <Table.HeadCell>Site Adı</Table.HeadCell>
            <Table.HeadCell>Whatsapp isim</Table.HeadCell>
            <Table.HeadCell>Whatsapp Durum</Table.HeadCell>
            <Table.HeadCell>Whatsapp Logo</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {sites.map((item) => {
              const { _id, name, UID, displayName, aboutText, profilePicture } =
                item;
              return (
                <Table.Row key={_id}>
                  <Table.Cell>{UID}</Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{displayName}</Table.Cell>
                  <Table.Cell>{aboutText}</Table.Cell>
                  <Table.Cell>
                    {profilePicture ? (
                      <img
                        src={profilePicture}
                        style={{ width: 40, height: 'auto', display: 'block' }}
                      />
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell className="flex justify-end">
                    <Button
                      color="gray"
                      size="sm"
                      as={Link}
                      to={`/site/${_id}`}
                    >
                      <HiPencil />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </CardContainer>
  );
};
