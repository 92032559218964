import { FC, useEffect, useState, useCallback } from 'react';
import {
  Badge,
  Spinner,
  Table,
  Button,
  Modal,
  TextInput,
  Textarea,
} from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import { HiPencil, HiTrash } from 'react-icons/hi';
import {
  addMessageTemplate,
  deleteMessageTemplate,
  getMessageTemplates,
} from '../../services';
import { IMessageTemplate } from '../../types';
import { useGlobal } from '../../hooks/useGlobal';
import { Msg } from '../../consts';
import { CardContainer } from '../../layout';

export const MessageTemplateMain: FC = () => {
  const { showAlert } = useGlobal();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [newTemplatePayload, setNewTemplatePayload] = useState({
    name: '',
    message: '',
  });
  const [messageTemplates, setMessageTemplates] = useState<IMessageTemplate[]>(
    [],
  );

  const fetchData = useCallback(async () => {
    try {
      const response: any = await getMessageTemplates();
      setMessageTemplates(response.data);
    } catch (error) {
      console.error('getMessageTemplates error:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = (templateId: string) => {
    showAlert({
      text: Msg.MessageTemplatedelete,
      icon: 'question',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteMessageTemplate(templateId);
          navigate(0);
        } catch (error) {
          console.error('deleteMessageTemplate error:', error);
        }
      }
    });
  };

  const handleNewTemplate = async () => {
    setModalLoading(true);
    try {
      const response: any = await addMessageTemplate(newTemplatePayload);
      const { _id: id } = response.data;

      navigate(`/message-template/${id}`, { replace: true });
    } catch (error: any) {
      showAlert({
        text: error.data.error,
        icon: 'warning',
      });
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <>
      <CardContainer
        title="Mesaj Taslakları"
        action={() => (
          <Button size="sm" color="light" onClick={() => setOpenModal(true)}>
            Taslak Ekle
          </Button>
        )}
      >
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Table striped hoverable>
            <Table.Head>
              <Table.HeadCell>#</Table.HeadCell>
              <Table.HeadCell>Başlık</Table.HeadCell>
              <Table.HeadCell>Mesajlar</Table.HeadCell>
              <Table.HeadCell>Dil</Table.HeadCell>
              <Table.HeadCell>Durum</Table.HeadCell>
              <Table.HeadCell></Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {messageTemplates.map((item, index) => {
                const { _id, name, messages, country, status } = item;
                return (
                  <Table.Row key={_id}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{messages.length}</Table.Cell>
                    <Table.Cell>{country}</Table.Cell>
                    <Table.Cell>
                      <div className="flex">
                        <div className="inline-block">
                          <Badge color={status ? 'success' : 'yellow'}>
                            {status ? 'Aktif' : 'Pasif'}
                          </Badge>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="flex justify-end">
                      <Button.Group>
                        <Button
                          color="gray"
                          size="sm"
                          as={Link}
                          to={`/message-template/${_id}`}
                        >
                          <HiPencil />
                        </Button>
                        <Button
                          color="gray"
                          size="sm"
                          onClick={() => handleDelete(_id)}
                        >
                          <HiTrash />
                        </Button>
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </CardContainer>

      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Mesaj Taslak Ekle</Modal.Header>
        <Modal.Body>
          <TextInput
            type="text"
            placeholder="Başlık"
            className="mb-4"
            onChange={(e) => {
              setNewTemplatePayload((p) => ({
                ...p,
                name: e.target.value,
              }));
            }}
            value={newTemplatePayload.name}
          />
          <Textarea
            placeholder="Mesaj"
            onChange={(e) => {
              setNewTemplatePayload((p) => ({
                ...p,
                message: e.target.value,
              }));
            }}
            value={newTemplatePayload.message}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleNewTemplate}
            isProcessing={modalLoading}
            disabled={modalLoading}
          >
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
