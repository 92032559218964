import { FC, useCallback, useEffect, useState } from 'react';
import { Spinner, Table } from 'flowbite-react';
import { getDeviceMessagesById } from '../../services';
import { formatDate } from '../../utils';

interface IMessage {
  date: string;
  contactCardCount: number;
  isAnsweredCount: number;
  messageCount: number;
}

interface IMessageTotal {
  contactCardCount: number;
  isAnsweredCount: number;
  messageCount: number;
}

interface IDeviceMessages {
  id: string;
}

export const DeviceMessages: FC<IDeviceMessages> = ({
  id,
}: IDeviceMessages) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [messageListTotal, setMessageListTotal] = useState<IMessageTotal>({
    contactCardCount: 0,
    isAnsweredCount: 0,
    messageCount: 0,
  });
  const [totalMessage, setTotalMessage] = useState<string>('');

  const renderData = (data: any) => {
    const totals = data.dailyMessage.reduce(
      (acc: any, cur: any) => {
        return {
          contactCardCount: acc.contactCardCount + cur.contactCardCount,
          isAnsweredCount: acc.isAnsweredCount + cur.isAnsweredCount,
          messageCount: acc.messageCount + cur.messageCount,
        };
      },
      {
        contactCardCount: 0,
        isAnsweredCount: 0,
        messageCount: 0,
      },
    );

    setMessageListTotal(totals);
    setMessages(data.dailyMessage);
    setTotalMessage(
      `${
        data.number ? `${data.number} Numarasıyla ` : ``
      }Gönderilen Mesaj: <b>${totals.messageCount}</b>, Kişi Kartı: <b>${
        totals.contactCardCount
      }</b>, Cevap: <b>${totals.isAnsweredCount}</b>`,
    );
  };

  const fetchDeviceMessages = useCallback(async () => {
    try {
      const res: any = await getDeviceMessagesById(id);
      renderData(res.data);
    } catch (error) {
      console.error('fetchDeviceMessages: ', error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchDeviceMessages();
  }, [fetchDeviceMessages]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div
            className="mb-4 text-gray-500 text-sm"
            dangerouslySetInnerHTML={{ __html: totalMessage }}
          />
          <div className="overflow-x-auto max-h-80">
            <Table hoverable>
              <Table.Head>
                <Table.HeadCell></Table.HeadCell>
                <Table.HeadCell className="text-right">
                  Kişi Kart
                </Table.HeadCell>
                <Table.HeadCell className="text-right">Cevap</Table.HeadCell>
                <Table.HeadCell className="text-right">Mesaj</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {messages.map((message, index) => {
                  const {
                    date,
                    contactCardCount,
                    isAnsweredCount,
                    messageCount,
                  } = message;
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>{formatDate(date)}</Table.Cell>
                      <Table.Cell className="text-right">
                        {contactCardCount}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {isAnsweredCount}
                      </Table.Cell>
                      <Table.Cell className="text-right">
                        {messageCount}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell className="text-right">
                    {messageListTotal.contactCardCount}
                  </Table.Cell>
                  <Table.Cell className="text-right">
                    {messageListTotal.isAnsweredCount}
                  </Table.Cell>
                  <Table.Cell className="text-right">
                    {messageListTotal.messageCount}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </>
      )}
    </>
  );
};
