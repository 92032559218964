import { FC, useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IUser, useGlobal } from '../../hooks/useGlobal';

interface IHeader {
  user: IUser;
}

export const Header: FC<IHeader> = ({ user }: IHeader) => {
  const { logout } = useGlobal();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userMenuRef = useRef<HTMLDivElement>(null);

  const [userToggle, setUserToggle] = useState<boolean>(false);

  const closeUserMenu = (e: MouseEvent) => {
    if (
      userMenuRef.current &&
      !userMenuRef.current.contains(e.target as Node)
    ) {
      setUserToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeUserMenu);

    return () => {
      document.removeEventListener('mousedown', closeUserMenu);
    };
  }, []);

  const hangleLogout = () => {
    const confirmBtn = confirm('Çıkış yapmak istediğinize emin misiniz?');
    if (confirmBtn) {
      logout();

      navigate('/', { replace: true });
    }
  };

  return (
    <header>
      <nav className="fixed z-30 w-full bg-white border-b border-gray-200 py-3 px-4">
        <div className="flex justify-between items-center max-w-screen-xl mx-auto">
          {/* Navigation */}
          <div className="flex justify-start items-center">
            <Link to="/" className="flex mr-14">
              <span className="self-center hidden sm:flex text-2xl font-semibold whitespace-nowrap">
                WPInvite
              </span>
            </Link>
            <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
              <ul className="flex flex-col mt-4 space-x-6 text-sm font-medium lg:flex-row xl:space-x-8 lg:mt-0">
                <li>
                  <Link
                    to="/"
                    className={`block ${
                      pathname === '/' ? 'text-primary-700' : 'text-gray-700'
                    } hover:text-primary-700`}
                  >
                    Cihaz Listesi
                  </Link>
                </li>
                <li>
                  <Link
                    to="/numbers"
                    className={`block ${
                      pathname === '/numbers'
                        ? 'text-primary-700'
                        : 'text-gray-700'
                    } hover:text-primary-700`}
                  >
                    Numaralar
                  </Link>
                </li>
                <li>
                  <Link
                    to="/statistics"
                    className={`block ${
                      pathname === '/statistics'
                        ? 'text-primary-700'
                        : 'text-gray-700'
                    } hover:text-primary-700`}
                  >
                    İstatistikler
                  </Link>
                </li>
                <li>
                  <Link
                    to="/settings"
                    className={`block ${
                      pathname === '/settings'
                        ? 'text-primary-700'
                        : 'text-gray-700'
                    } hover:text-primary-700`}
                  >
                    Ayarlar
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* Navigation */}

          {/* User Menu */}
          <div className="flex items-center">
            <div className="flex items-center relative" ref={userMenuRef}>
              <button
                type="button"
                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                onClick={() => setUserToggle(!userToggle)}
              >
                <span className="sr-only">Open user menu</span>
                <img
                  className="w-8 h-8 rounded-full"
                  src={require('../../assets/images/user-default.jpg')}
                />
              </button>
              <div
                className={`${
                  userToggle ? 'block' : 'hidden'
                } absolute top-4 right-0 z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow`}
              >
                <div className="px-4 py-3">
                  <p className="text-sm text-gray-900 dark:text-white">
                    {user.name}
                  </p>
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {user.email}
                  </p>
                </div>
                <ul className="py-1">
                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={hangleLogout}
                    >
                      Çıkış Yap
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* User Menu */}
        </div>
      </nav>
    </header>
  );
};
