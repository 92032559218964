import { Badge } from 'flowbite-react';
import {
  HiClock,
  HiCheck,
  HiOutlineQrcode,
  HiExclamationCircle,
  HiOutlineRefresh,
} from 'react-icons/hi';

import { WAClientStatus } from '../../types';

export const ClientStatus = (status: any) => {
  let badgeColor = '';
  let badgeIcon: any;

  if (status === WAClientStatus.CONNECTED) {
    badgeColor = 'success';
    badgeIcon = HiCheck;
  } else if (status === WAClientStatus.UNPAIRED) {
    badgeColor = 'indigo';
    badgeIcon = HiOutlineQrcode;
  } else if (
    status === WAClientStatus.ERROR ||
    status === WAClientStatus.DISCONNECTED
  ) {
    badgeColor = 'failure';
    badgeIcon = HiExclamationCircle;
  } else if (status === WAClientStatus.OPENING) {
    badgeColor = '';
    badgeIcon = HiClock;
  } else if (status === WAClientStatus.RESTART) {
    badgeColor = 'warning';
    badgeIcon = HiOutlineRefresh;
  }

  return (
    <Badge color={badgeColor} icon={badgeIcon}>
      <p className="capitalize">{status}</p>
    </Badge>
  );
};
