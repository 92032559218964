import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useGlobal } from '../hooks/useGlobal';
import { Footer, Header } from '../components';

export const RootLayout: FC = () => {
  const { user } = useGlobal();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Header user={user} />
      <div className="flex pt-16">
        <div className="relative w-full max-w-screen-xl mx-auto h-full">
          <main className="my-2">
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};
