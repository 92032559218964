import { memo, useEffect, useState, FC } from 'react';
import moment from 'moment';

interface Props {
  date: string;
}

export const TimeAgo: FC<Props> = ({ date }: Props): any => {
  const [timeAgo, setTimeAgo] = useState<string>('');

  useEffect(() => {
    const updateFromNow = () => {
      const now = moment();
      const diffSeconds = now.diff(date, 'seconds');
      const diffMinutes = now.diff(date, 'minutes');
      const diffHours = now.diff(date, 'hours');
      const diffDays = now.diff(date, 'days');
      const diffWeeks = now.diff(date, 'weeks');
      const diffMonths = now.diff(date, 'months');
      const diffYears = now.diff(date, 'years');

      if (diffSeconds < 60) {
        setTimeAgo(`${diffSeconds} saniye önce`);
      } else if (diffMinutes < 60) {
        setTimeAgo(`${diffMinutes} dakika önce`);
      } else if (diffHours < 24) {
        setTimeAgo(`${diffHours} saat önce`);
      } else if (diffDays < 7) {
        setTimeAgo(`${diffDays} gün önce`);
      } else if (diffWeeks < 4) {
        setTimeAgo(`${diffWeeks} hafta önce`);
      } else if (diffMonths < 12) {
        setTimeAgo(`${diffMonths} ay önce`);
      } else {
        setTimeAgo(`${diffYears} yıl önce`);
      }
    };

    updateFromNow();
    const interval = setInterval(updateFromNow, 60000);

    return () => clearInterval(interval);
  }, [date]);

  return timeAgo;
};
