import { FC, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Select,
  Spinner,
  TextInput,
  Card,
  Checkbox,
  Badge,
} from 'flowbite-react';
import {
  HiOutlineInformationCircle,
  HiArrowSmUp,
  HiFilter,
  HiQrcode,
  HiOutlineCog,
  HiExternalLink,
  HiOutlinePhone,
  HiOutlineLocationMarker,
  HiCheck,
  HiExclamationCircle,
} from 'react-icons/hi';
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import CountUp from 'react-countup';
import QRCode from 'react-qr-code';

import {
  getDeviceById,
  getDevices,
  getFilterProxyList,
  getQuickStatistics,
  resetDevice,
  resetMultipleDevice,
  saveDevice,
  updateAllDevices,
} from '../../services';
import {
  ClientStatus,
  GetSite,
  InputLabel,
  TimeAgo,
  isNumber,
  sites,
} from '../../components';
import { IStatistics, WAClientStatus } from '../../types';
import { useGlobal } from '../../hooks/useGlobal';
import { Msg } from '../../consts';
import { CardContainer } from '../../layout';

interface IDevice {
  name: string;
  ip: string;
  number: string;
  siteId: string;
}

interface IFilter {
  q: string;
  clientStatus: string;
  siteId: string;
  ek: string;
  proxyUrl: string;
}

interface IDeviceSetting {
  dailyLimit: number;
}

const initialState: IDevice = {
  name: '',
  ip: '',
  number: '',
  siteId: '',
};

const initialFilter: IFilter = {
  q: '',
  clientStatus: '',
  siteId: '',
  ek: '',
  proxyUrl: '',
};

const initialDeviceSetting: IDeviceSetting = {
  dailyLimit: 0,
};

export const DashboardPage: FC = () => {
  const { showAlert } = useGlobal();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [devices, setDevices] = useState<any[]>([]);
  const [filterProxyList, setFilterProxyList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [statisticModal, showStatisticModal] = useState<boolean>(false);
  const [statisticModalLoading, setStatisticModalLoading] =
    useState<boolean>(false);
  const [statistics, setStatistics] = useState<IStatistics>();
  const [newDeviceLoading, setNewDeviceLoading] = useState<boolean>(false);
  const [newDeviceModal, showNewDeviceModal] = useState<boolean>(false);
  const [newDevicePayload, setNewDevicePayload] =
    useState<IDevice>(initialState);
  const [deviceFilter, setDeviceFilter] = useState<IFilter>(initialFilter);
  const [deviceSettingLoading, setDeviceSettingLoading] =
    useState<boolean>(false);
  const [deviceSettingModal, showDeviceSettingModal] = useState<boolean>(false);
  const [deviceSettingPayload, setDeviceSettingPayload] =
    useState<IDeviceSetting>(initialDeviceSetting);
  const [QRModal, showQRModal] = useState<boolean>(false);
  const [qr, setQr] = useState<string | null>(null);
  const [qrLastUpdate, setQrLastUpdate] = useState<string | null>(null);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const [multipleDeviceResetBtn, setMultipleDeviceResetBtn] =
    useState<boolean>(false);

  const handleSelectedDevice = (id: string) => {
    if (selectedDeviceIds.includes(id)) {
      setSelectedDeviceIds(
        selectedDeviceIds.filter((deviceId: string) => deviceId !== id),
      );
    } else {
      setSelectedDeviceIds([...selectedDeviceIds, id]);
    }
  };

  const handleUpdateMultipleDevices = async () => {
    showAlert({
      text: `Seçili ${selectedDeviceIds.length} tane cihaz resetlenecek`,
      icon: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setMultipleDeviceResetBtn(true);
        resetMultipleDevice({
          deviceIds: selectedDeviceIds,
        })
          .then(() => {
            showAlert({
              text: Msg.Success,
              icon: 'success',
            });
          })
          .catch(() => {})
          .finally(() => {
            setMultipleDeviceResetBtn(false);
          });
      }
    });
  };

  const fetchDevices = async (params: IFilter | undefined) => {
    try {
      const response: any = await getDevices(params);
      setDevices(response.data);
    } catch (error) {
      console.error('fetchDevices:', error);
    } finally {
      setLoading(false);
      setFilterLoading(false);
    }
  };

  const fetchFilterProxyList = async () => {
    try {
      const response: any = await getFilterProxyList();
      setFilterProxyList(response.data);
    } catch (error) {
      console.error('fetchFilterProxyList:', error);
    } finally {
      setLoading(false);
      setFilterLoading(false);
    }
  };

  const showQRModalHandler = async (id: string) => {
    try {
      showQRModal(true);
      const res: any = await getDeviceById(id);

      // set qr
      setQr(res.data.remote.qr);
      const dateFormat = new Date(res.data.updatedAt);
      setQrLastUpdate(dateFormat.toLocaleString('tr-TR'));
    } catch (error) {
      console.error('showQRModalHandler', error);
    }
  };

  const hideQRModalHandler = () => {
    showQRModal(false);
    setQr(null);
  };

  const deviceResetHandler = (id: string) => {
    showAlert({
      text: Msg.DeviceReset,
      icon: 'question',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedDevices = devices.map((device) =>
          device._id === id ? { ...device, listLoading: true } : device,
        );
        setDevices(updatedDevices);

        await resetDevice(id)
          .then(() => {
            const updatedDevices = devices.map((device) =>
              device._id === id
                ? {
                    ...device,
                    clientStatus: WAClientStatus.RESTART,
                    listLoading: false,
                  }
                : device,
            );
            setDevices(updatedDevices);
          })
          .catch((err) => {
            console.error('deviceReset:', err);
          });
      }
    });
  };

  const fetchStatistics = async () => {
    try {
      setStatisticModalLoading(true);
      const response: any = await getQuickStatistics();
      setStatistics(response.data);
    } catch (error) {
      console.error('fetchStatistics: ', error);
    } finally {
      setStatisticModalLoading(false);
    }
  };

  const newDeviceHandler = async () => {
    setNewDeviceLoading(true);

    try {
      await saveDevice(newDevicePayload);
    } catch (error) {
      console.error('newDeviceHandler:', error);
    } finally {
      setNewDeviceLoading(false);
    }
  };

  const saveDeviceSetting = async () => {
    showAlert({
      text: Msg.DeviceSettingSave,
      icon: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setDeviceSettingLoading(true);

        updateAllDevices({
          ...deviceSettingPayload,
          deviceIds: selectedDeviceIds,
        })
          .then(() => {
            showAlert({
              text: Msg.Success,
              icon: 'success',
            });
          })
          .catch(() => {})
          .finally(() => {
            setDeviceSettingLoading(false);
          });
      }
    });
  };

  const filterHandler = () => {
    const filteredDeviceFilter: any = Object.fromEntries(
      Object.entries(deviceFilter).filter(([_, value]) => value !== ''),
    );

    if (Object.keys(filteredDeviceFilter).length > 0) {
      setFilterLoading(true);
      navigate({
        pathname: '/',
        search: createSearchParams(filteredDeviceFilter).toString(),
      });
    }
  };

  useEffect(() => {
    const filter: any = {};
    searchParams.forEach((value, key) => {
      filter[key] = value;
    });

    setDeviceFilter(filter);

    const fetchData = async () => {
      try {
        // fetch devices
        await fetchDevices(filter);

        // fetch filter proxy list
        await fetchFilterProxyList();

        // fetch statistics
        await fetchStatistics();
      } catch (error) {
        console.error('fetchData:', error);
      }
    };

    fetchData();
  }, [searchParams]);

  return (
    <>
      <div className="grid w-full grid-cols-4 gap-4 mb-4">
        <Card>
          <div className="flex justify-between items-center	mb-2">
            <h3 className="text-base font-medium text-gray-500">
              İstatistikler
            </h3>
            <div onClick={() => showStatisticModal(true)}>
              <HiOutlineInformationCircle />
            </div>
          </div>
          <div className="flex justify-between mb-2">
            <div className="text-sm font-normal text-gray-500">Toplam:</div>
            <div className="text-sm font-medium	">
              <CountUp end={statistics?.devices.total || 0} />
            </div>
          </div>
          <div className="flex justify-between mb-2 gap-4">
            <div className="flex justify-between w-full">
              <div className="text-sm font-normal text-gray-500">
                Connected:
              </div>
              <div className="text-sm font-medium	">
                <CountUp end={statistics?.devices.connected || 0} />
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="text-sm font-normal text-gray-500">Unpaired:</div>
              <div className="text-sm font-medium	">
                <CountUp end={statistics?.devices.unpaired || 0} />
              </div>
            </div>
          </div>
          <div className="flex justify-between mb-2 gap-4">
            <div className="flex justify-between w-full">
              <div className="text-sm font-normal text-gray-500">Opening:</div>
              <div className="text-sm font-medium	">
                <CountUp end={statistics?.devices.opening || 0} />
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="text-sm font-normal text-gray-500">Error:</div>
              <div className="text-sm font-medium	">
                <CountUp end={statistics?.devices.error || 0} />
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className="items-center">
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Toplam Mesaj
            </h3>
            <div className="mb-2">
              <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">
                <CountUp end={statistics?.totalMessage || 0} />
              </span>
            </div>
            <div>
              <p className="flex items-center text-base font-normal text-gray-500 dark:text-gray-400">
                <span className="flex items-center mr-2 text-sm text-green-500 dark:text-green-400">
                  <HiArrowSmUp className="w-4" />
                  0%
                </span>
                Geçen aya göre
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="items-center">
            <h3 className="mb-2 text-base font-medium text-gray-500">
              İletilen Mesaj
            </h3>
            <div className="mb-2">
              <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">
                <CountUp end={statistics?.totalSentMessages || 0} />
              </span>
            </div>
            <div>
              <p className="flex items-center text-base font-normal text-gray-500 dark:text-gray-400">
                <span className="flex items-center mr-2 text-sm text-green-500 dark:text-green-400">
                  <HiArrowSmUp className="w-4" />
                  0%
                </span>
                Geçen aya göre
              </p>
            </div>
          </div>
        </Card>
        <Card>
          <div className="items-center">
            <h3 className="mb-2 text-base font-medium text-gray-500">
              Kişi Kartı
            </h3>
            <div className="mb-2">
              <span className="text-2xl font-bold leading-none text-gray-900 sm:text-3xl dark:text-white">
                <CountUp end={statistics?.totalContactCardSent || 0} />
              </span>
            </div>
            <div>
              <p className="flex items-center text-base font-normal text-gray-500 dark:text-gray-400">
                <span className="flex items-center mr-2 text-sm text-green-500 dark:text-green-400">
                  <HiArrowSmUp className="w-4" />
                  0%
                </span>
                Geçen aya göre
              </p>
            </div>
          </div>
        </Card>
      </div>

      <CardContainer title="Cihaz Listesi">
        {/* device filter  */}
        <div className="items-center flex justify-between">
          <div className="flex gap-4">
            <TextInput
              placeholder="Ad, Ip ya da Numara"
              value={deviceFilter.q}
              onChange={(e: any) => {
                setDeviceFilter({
                  ...deviceFilter,
                  q: e.target.value,
                });
              }}
            />
            <Select
              onChange={(e: any) => {
                setDeviceFilter({
                  ...deviceFilter,
                  clientStatus: e.target.value,
                });
              }}
            >
              <option value="">Status</option>
              <option value="CONNECTED">Connected</option>
              <option value="UNPAIRED">Unpaired</option>
              <option value="ERROR">Error</option>
              <option value="OPENING">Opening</option>
              <option value="RESTART">Restart</option>
            </Select>
            <Select
              onChange={(e: any) => {
                setDeviceFilter({
                  ...deviceFilter,
                  siteId: e.target.value,
                });
              }}
            >
              <option value="">Site</option>
              {sites.map((site) => (
                <option value={site.id} key={site.id}>
                  {site.name}
                </option>
              ))}
            </Select>
            <Select
              onChange={(e: any) => {
                setDeviceFilter({
                  ...deviceFilter,
                  ek: e.target.value,
                });
              }}
            >
              <option value="">Cihazlar</option>
              <option value="wpinvite">wpinvite</option>
              <option value="waapi">waapi</option>
            </Select>
            <Select
              onChange={(e: any) => {
                setDeviceFilter({
                  ...deviceFilter,
                  proxyUrl: e.target.value,
                });
              }}
            >
              <option value="">Proxy</option>
              {filterProxyList.map((proxy: any) => (
                <option value={proxy} key={proxy}>
                  {proxy}
                </option>
              ))}
            </Select>
            <Button
              color="light"
              onClick={filterHandler}
              isProcessing={filterLoading}
              disabled={filterLoading}
            >
              <HiFilter />
            </Button>
          </div>
          <div className="items-center flex gap-2">
            <Button
              color="blue"
              onClick={() => showDeviceSettingModal(true)}
              title="Cihaz Ayarları"
              size="md"
              disabled={selectedDeviceIds.length === 0}
            >
              <HiOutlineCog className="mr-2" />
              Seçili Cihaz ({selectedDeviceIds.length})
            </Button>
          </div>
        </div>

        {/* device list */}
        <div className="flex flex-col mt-4">
          <div className="rounded-lg">
            <div className="inline-block min-w-full align-middle">
              {loading ? (
                <div className="text-center">
                  <Spinner />
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="p-4 text-xs font-medium tracking-wider text-center text-gray-500">
                        #
                      </th>
                      <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                        Id
                      </th>
                      <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                        Adı
                      </th>
                      <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500"></th>
                      <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                        Site
                      </th>
                      <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                        Son Mesaj
                      </th>
                      <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                        Status
                      </th>
                      <th className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                        Gönderim
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {devices.map((device, index) => {
                      const {
                        _id: id,
                        name,
                        number,
                        clientStatus,
                        siteId,
                        listLoading,
                        isExternal,
                        ip,
                        lastMessage,
                        isMessageSent,
                      } = device;

                      return (
                        <tr
                          key={index}
                          className={`hover:bg-gray-200 ${
                            index % 2 === 0 ? 'bg-gray-100' : ''
                          }`}
                        >
                          <td className="px-2 text-sm font-normal text-gray-900 w-4 text-center">
                            <Checkbox
                              onChange={() => handleSelectedDevice(id)}
                              checked={selectedDeviceIds.includes(id)}
                            />
                          </td>
                          <td className="p-2 text-sm font-normal text-gray-900 whitespace-nowrap">
                            <div className="flex items-center">
                              {id}
                              {isExternal && (
                                <HiExternalLink className="ml-2" />
                              )}
                            </div>
                          </td>
                          <td className="p-2 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {name}
                          </td>
                          <td className="p-2 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                            <div>
                              {number && (
                                <div className="flex items-center">
                                  <HiOutlinePhone className="mr-2" /> {number}
                                </div>
                              )}
                              {!isExternal && (
                                <div className="mt-2 flex items-center">
                                  <HiOutlineLocationMarker className="mr-2" />
                                  {ip}
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="p-2 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {GetSite(parseInt(siteId))}
                          </td>
                          <td className="p-2 text-sm font-normal text-gray-500 whitespace-nowrap dark:text-gray-400">
                            {lastMessage ? (
                              <>
                                <TimeAgo date={lastMessage?.createdAt} />
                                {lastMessage.isAnswered
                                  ? ' / Cevapladı'
                                  : ' / Gönderildi'}
                              </>
                            ) : null}
                          </td>
                          <td className="p-2">
                            <div className="flex">
                              {listLoading && <Spinner size="sm" />}
                              <div className="inline-block">
                                {ClientStatus(clientStatus)}
                              </div>
                            </div>
                          </td>
                          <td className="p-2">
                            <div className="flex">
                              <Badge
                                color={isMessageSent ? 'success' : 'warning'}
                                icon={
                                  isMessageSent ? HiCheck : HiExclamationCircle
                                }
                              >
                                <p className="uppercase">
                                  {isMessageSent ? 'Aktif' : 'Pasif'}
                                </p>
                              </Badge>
                            </div>
                          </td>
                          <td>
                            <div className="flex items-center justify-end">
                              <Button.Group className="mr-2">
                                <Button
                                  size="sm"
                                  color="light"
                                  title="QR Tara"
                                  onClick={async () => showQRModalHandler(id)}
                                  disabled={
                                    clientStatus !== WAClientStatus.UNPAIRED
                                  }
                                >
                                  <HiQrcode />
                                </Button>
                                <Button
                                  size="sm"
                                  color="light"
                                  as={Link}
                                  to={`/device/${id}`}
                                  title="Cihaz Detay"
                                >
                                  <HiOutlineInformationCircle />
                                </Button>
                              </Button.Group>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </CardContainer>

      <Modal show={newDeviceModal} onClose={() => showNewDeviceModal(false)}>
        <Modal.Header>Yeni Cihaz Ekle</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <InputLabel
              title="Ad"
              onChange={(e: any) => {
                setNewDevicePayload({
                  ...newDevicePayload,
                  name: e.target.value,
                });
              }}
            />
            <InputLabel
              title="IP"
              placeholder="127.0.0.1"
              onChange={(e: any) => {
                setNewDevicePayload({
                  ...newDevicePayload,
                  ip: e.target.value,
                });
              }}
            />
            <InputLabel
              title="Numara"
              placeholder="905xxxxxxxxx"
              value={newDevicePayload.number}
              onChange={(e: any) => {
                if (!isNumber(e.target.value)) {
                  return;
                }

                setNewDevicePayload({
                  ...newDevicePayload,
                  number: e.target.value,
                });
              }}
            />
            <Select
              name="siteId"
              required
              onChange={(e: any) => {
                setNewDevicePayload({
                  ...newDevicePayload,
                  siteId: e.target.value,
                });
              }}
            >
              <option value="">Site</option>
              {sites.map((site) => (
                <option value={site.id} key={site.id}>
                  {site.name}
                </option>
              ))}
            </Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={newDeviceHandler}
            isProcessing={newDeviceLoading}
            disabled={newDeviceLoading}
          >
            Kaydet
          </Button>
          <Button
            color="gray"
            onClick={() => showNewDeviceModal(false)}
            disabled={newDeviceLoading}
          >
            İptal
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={QRModal} onClose={hideQRModalHandler}>
        <Modal.Header>QR Kodu</Modal.Header>
        <Modal.Body>
          {!qr ? (
            <div className="text-center">
              <Spinner size="xl" />
            </div>
          ) : (
            <>
              <QRCode
                value={qr}
                size={250}
                style={{ width: '100%', maxWidth: '100%', height: 'auto' }}
              />
              <div className="text-sm text-gray-400 mt-2">
                Son güncelleme: {qrLastUpdate}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={deviceSettingModal}
        onClose={() => showDeviceSettingModal(false)}
      >
        <Modal.Header>Cihaz Ayarları</Modal.Header>
        <Modal.Body>
          <InputLabel
            title="Günlük Mesaj Gönderim Adet"
            type="number"
            value={deviceSettingPayload.dailyLimit}
            onChange={(e: any) => {
              setDeviceSettingPayload({
                dailyLimit: parseInt(e.target.value),
              });
            }}
            containerClass="mb-4"
          />

          <Button
            onClick={handleUpdateMultipleDevices}
            isProcessing={multipleDeviceResetBtn}
            disabled={multipleDeviceResetBtn}
          >
            Seçili Cihazları Resetle
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={saveDeviceSetting}
            isProcessing={deviceSettingLoading}
            disabled={deviceSettingLoading}
          >
            Güncelle
          </Button>
          <Button
            color="gray"
            onClick={() => showDeviceSettingModal(false)}
            disabled={deviceSettingLoading}
          >
            İptal
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={statisticModal} onClose={() => showStatisticModal(false)}>
        <Modal.Header>İstatistikler</Modal.Header>
        <Modal.Body className="text-sm">
          {/* daily message start */}
          <h5 className="font-bold text-gray-900 bg-gray-100 px-2 py-2.5 my-2">
            Günlük gönderilen mesaj
          </h5>
          <div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Mesaj Kuyruk Sayısı</span>
              <span>{statistics?.queueCount || 0}</span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Toplam Mesaj</span>
              <span>{statistics?.daily.totalMessage || 0}</span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Betkanyon</span>
              <span>
                {statistics?.daily.messageCountBySiteId.betkanyon || 0}
              </span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Winxbet</span>
              <span>{statistics?.daily.messageCountBySiteId.winxbet || 0}</span>
            </div>
            {/* <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>WinxbetBr</span>
              <span>
                {statistics?.daily.messageCountBySiteId.winxbetBr || 0}
              </span>
            </div> */}
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Bahisal</span>
              <span>{statistics?.daily.messageCountBySiteId.bahisal || 0}</span>
            </div>
            {/* <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Unibahis</span>
              <span>
                {statistics?.daily.messageCountBySiteId.unibahis || 0}
              </span>
            </div> */}
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Betloto</span>
              <span>{statistics?.daily.messageCountBySiteId.betloto || 0}</span>
            </div>
          </div>
          {/* daily message end */}
          <h5 className="font-bold text-gray-900 bg-gray-100 px-2 py-2.5 my-2">
            Cihazlar
          </h5>
          <div>
            <div className="justify-between flex hover:bg-slate-100 p-2">
              <span>Toplam</span>
              <span>{statistics?.devices.total || 0}</span>
            </div>
            <div className="justify-between flex">
              <div className="w-full justify-between flex hover:bg-slate-100 p-2">
                <span>Connected</span>
                <span>{statistics?.devices.connected || 0}</span>
              </div>
              <div className="w-full justify-between flex hover:bg-slate-100 p-2">
                <span>Unpaired</span>
                <span>{statistics?.devices.unpaired || 0}</span>
              </div>
            </div>
            <div className="justify-between flex">
              <div className="w-full justify-between flex hover:bg-slate-100 p-2">
                <span>Error</span>
                <span>{statistics?.devices.error || 0}</span>
              </div>
              <div className="w-full justify-between flex hover:bg-slate-100 p-2">
                <span>Opening</span>
                <span>{statistics?.devices.opening || 0}</span>
              </div>
            </div>
          </div>
          <h5 className="font-bold text-gray-900 bg-gray-100 px-2 py-2.5 my-2">
            Sitelere göre bağlı cihaz sayısı
          </h5>
          <div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Betkanyon</span>
              <span>{statistics?.sites.betkanyon || 0}</span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Winxbet</span>
              <span>{statistics?.sites.winxbet || 0}</span>
            </div>
            {/* <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>WinxbetBr</span>
              <span>{statistics?.sites.winxbetBr || 0}</span>
            </div> */}
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Bahisal</span>
              <span>{statistics?.sites.bahisal || 0}</span>
            </div>
            {/* <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Unibahis</span>
              <span>{statistics?.sites.unibahis || 0}</span>
            </div> */}
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Betloto</span>
              <span>{statistics?.sites.betloto || 0}</span>
            </div>
          </div>
          <h5 className="font-bold text-gray-900 bg-gray-100 px-2 py-2.5 my-2">
            Mesajlar
          </h5>
          <div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Toplam</span>
              <span>{statistics?.totalMessage || 0}</span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>İletilen</span>
              <span>{statistics?.totalSentMessages || 0}</span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Görülen</span>
              <span>{statistics?.totalMessagesSeen || 0}</span>
            </div>
            <div className="w-full justify-between flex hover:bg-slate-100 p-2">
              <span>Kişi Kartı</span>
              <span>{statistics?.totalContactCardSent || 0}</span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            isProcessing={statisticModalLoading}
            disabled={statisticModalLoading}
            onClick={() => {
              fetchStatistics();
            }}
            color="light"
          >
            Yenile
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
