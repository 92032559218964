import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { Button, Select, Spinner, ToggleSwitch } from 'flowbite-react';
import {
  deleteDevice,
  getDeviceById,
  resetDevice,
  sendMessage,
  updateDevice,
} from '../../services';
import {
  ClientStatus,
  DeviceLogs,
  DeviceMessages,
  InputLabel,
  isNumber,
  sites,
} from '../../components';
import { IDevice, WAClientStatus } from '../../types';
import { useGlobal } from '../../hooks/useGlobal';
import { useWebSocket } from '../../hooks/useSocket';
import { Msg } from '../../consts';
import { CardContainer } from '../../layout';

const initialMessagePayload = {
  phone: '',
  message: '',
};

export const DeviceDetail: FC = () => {
  const { showAlert } = useGlobal();
  const { latestMessage } = useWebSocket();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [deviceInfo, setDeviceInfo] = useState<IDevice>();

  const [formPayload, setFormPayload] = useState<{ [key: string]: any }>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [qr, setQr] = useState<string | null>(null);
  const [qrLoading, setQrLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [sendMessagePayload, setSendMessagePayload] = useState<{
    phone: string;
    message: string;
  }>(initialMessagePayload);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);

  // fetch device detail
  const fetchData = async (id: string) => {
    try {
      const res: any = await getDeviceById(id);

      // set device data
      setDeviceInfo(res.data);

      // set qr
      setQr(res.data.remote.qr);

      // set formload
      setFormPayload({
        name: res.data.name,
        number: res.data.number,
        port: res.data.port,
        dailyLimit: res.data.dailyLimit,
        note: res.data.note,
        siteId: res.data.siteId,
        proxyConfig: res.data.proxyConfig,
        priority: res.data.priority,
        isMessageSent: res.data.isMessageSent,
      });
    } catch (error) {
      console.error('fetchDeviceDetail: ', error);
    } finally {
      setLoading(false);
      setQrLoading(false);
    }
  };

  const handleFormPayload = (e: any) => {
    const { name, value } = e.target;

    if (name === 'number' && !isNumber(value)) {
      return;
    }

    if (
      name === 'proxyUrl' ||
      name === 'proxyAuthUsername' ||
      name === 'proxyAuthPassword'
    ) {
      const proxyConfig = formPayload.proxyConfig || {};
      if (name === 'proxyUrl') {
        proxyConfig.url = value;
      } else if (name === 'proxyAuthUsername') {
        proxyConfig.auth = {
          ...proxyConfig.auth,
          username: value,
        };
      } else if (name === 'proxyAuthPassword') {
        proxyConfig.auth = {
          ...proxyConfig.auth,
          password: value,
        };
      }

      setFormPayload({
        ...formPayload,
        proxyConfig,
      });

      return;
    }

    setFormPayload({
      ...formPayload,
      [name]: value,
    });
  };

  const deviceUpdateHandler = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    setFormLoading(true);

    await updateDevice(deviceInfo?._id || '', formPayload)
      .then(() => {
        showAlert({
          text: Msg.Success,
          icon: 'success',
        });
      })
      .catch(() => {
        showAlert({
          text: Msg.Error,
          icon: 'error',
        });
      })
      .finally(() => {
        setFormLoading(false);
      });
  };

  const deviceDeleteHandler = () => {
    showAlert({
      text: Msg.DeviceDelete,
      icon: 'question',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeleteLoading(true);
        await deleteDevice(deviceInfo?._id || '').then(() => {
          navigate('/', { replace: true });
        });
      }
    });
  };

  const deviceResetHandler = () => {
    showAlert({
      text: Msg.DeviceReset,
      icon: 'question',
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setResetLoading(true);
        await resetDevice(deviceInfo?._id || '').then(() => {
          location.reload();
        });
      }
    });
  };

  // send test message
  const sendMessageHandler = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    const { phone } = sendMessagePayload;

    const confirmAlert = await showAlert({
      text: `Bu numaraya ${phone} mesaj gönderilecek, onaylıyor musunuz?`,
      icon: 'question',
      showCancelButton: true,
    });

    if (confirmAlert.isConfirmed) {
      setSendMessageLoading(true);
      await sendMessage(deviceInfo?._id || '', {
        phone,
        message: 'wpinvite bağlı cihaz test mesajı',
      })
        .then(() => {
          showAlert({
            text: Msg.MessageSendSuccess,
            icon: 'success',
          });
        })
        .catch(() => {
          // TODO: error handler
        })
        .finally(() => {
          setSendMessageLoading(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const setFavicon = (href: string) => {
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = href;
  };

  useEffect(() => {
    const defaultTitle = 'WP Invite Panel';
    const updatedTitle = `${deviceInfo?._id ?? ''} | ${defaultTitle}`;
    document.title = updatedTitle;

    if (deviceInfo?.clientStatus === 'CONNECTED') {
      setFavicon('/favicon-online.ico?v=1');
    } else {
      setFavicon('/favicon-offline.ico?v=2');
    }

    return () => {
      document.title = defaultTitle;
      setFavicon('/favicon.ico?v=3');
    };
  }, [deviceInfo]);

  useEffect(() => {
    if (latestMessage && id) {
      const { eventType } = latestMessage as any;

      fetchData(id);
    }
  }, [latestMessage, id]);

  return (
    <>
      {loading ? (
        <div className="text-center py-20">
          <Spinner aria-label="device detail loading" size="xl" />
        </div>
      ) : (
        <div className="flex gap-4">
          {/* left */}
          <div className="w-[400px] flex-none">
            <div className="flex flex-col gap-4">
              {/* device status */}
              <CardContainer
                title="Bağlantı Durumu:"
                action={() => ClientStatus(deviceInfo?.clientStatus)}
              >
                <div>IP: {deviceInfo?.ip}</div>
              </CardContainer>
              {/* qr area */}
              {deviceInfo?.clientStatus === WAClientStatus.UNPAIRED && (
                <CardContainer
                  title="QR"
                  action={() => {
                    return (
                      <Button
                        size="xs"
                        color="blue"
                        isProcessing={qrLoading}
                        disabled={qrLoading}
                        onClick={() => {
                          setQrLoading(true);
                          fetchData(deviceInfo?._id);
                        }}
                      >
                        Yenile
                      </Button>
                    );
                  }}
                >
                  {deviceInfo?.isExternal ? (
                    <img
                      src={qr || ''}
                      alt="qr code"
                      className="w-full h-auto"
                    />
                  ) : (
                    <QRCode
                      value={qr || ''}
                      size={250}
                      style={{
                        width: '100%',
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  )}
                </CardContainer>
              )}
              {/* send message */}
              {deviceInfo?.clientStatus == WAClientStatus.CONNECTED && (
                <CardContainer title="Mesaj Gönder">
                  <form onSubmit={sendMessageHandler}>
                    <InputLabel
                      containerClass="mb-4"
                      title="Numara"
                      onChange={(e: any) => {
                        if (!isNumber(e.target.value)) {
                          return;
                        }

                        setSendMessagePayload({
                          ...sendMessagePayload,
                          phone: e.target.value,
                        });
                      }}
                      value={sendMessagePayload.phone}
                      required
                      placeholder="905xxxxxxxxx"
                    />
                    <Button
                      type="submit"
                      color="blue"
                      isProcessing={sendMessageLoading}
                      disabled={sendMessageLoading}
                    >
                      Gönder
                    </Button>
                  </form>
                </CardContainer>
              )}
            </div>
          </div>
          {/* right */}
          <div className="w-full gap-4 flex flex-col">
            <CardContainer title="Cihaz Bilgileri">
              <form onSubmit={deviceUpdateHandler}>
                <div className="flex gap-4 flex-col">
                  <div className="grid grid-cols-5 gap-6">
                    <InputLabel
                      title="Ad"
                      value={formPayload?.name}
                      name="name"
                      onChange={handleFormPayload}
                      required
                    />
                    <InputLabel
                      title="Numara"
                      value={formPayload?.number}
                      name="number"
                      type="number"
                      onChange={handleFormPayload}
                      required
                    />
                    <div>
                      <label
                        htmlFor="site"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Site
                      </label>
                      <Select
                        name="siteId"
                        required
                        onChange={handleFormPayload}
                      >
                        {sites.map((site) => (
                          <option
                            value={site.id}
                            key={site.id}
                            selected={parseInt(formPayload.siteId) === site.id}
                          >
                            {site.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <InputLabel
                      title="G. Mesaj Adet"
                      value={formPayload?.dailyLimit}
                      name="dailyLimit"
                      required
                      type="number"
                      onChange={handleFormPayload}
                    />
                    <div>
                      <label
                        htmlFor="priority"
                        className="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Öncelik
                      </label>
                      <Select
                        name="priority"
                        required
                        onChange={handleFormPayload}
                      >
                        <option
                          value="0"
                          selected={formPayload?.priority === 0}
                        ></option>
                        <option
                          value="1"
                          selected={formPayload?.priority === 1}
                        >
                          1
                        </option>
                        <option
                          value="2"
                          selected={formPayload?.priority === 2}
                        >
                          2
                        </option>
                        <option
                          value="3"
                          selected={formPayload?.priority === 3}
                        >
                          3
                        </option>
                        <option
                          value="4"
                          selected={formPayload?.priority === 4}
                        >
                          4
                        </option>
                        <option
                          value="5"
                          selected={formPayload?.priority === 5}
                        >
                          5
                        </option>
                      </Select>
                    </div>
                  </div>
                  {!deviceInfo?.isExternal && (
                    <div className="grid grid-cols-3 gap-6">
                      <InputLabel
                        title="Proxy Url"
                        value={formPayload?.proxyConfig?.url}
                        name="proxyUrl"
                        required
                        onChange={handleFormPayload}
                      />
                      <InputLabel
                        title="Proxy Username"
                        value={formPayload?.proxyConfig?.auth?.username}
                        name="proxyAuthUsername"
                        required
                        onChange={handleFormPayload}
                      />
                      <InputLabel
                        title="Proxy Password"
                        value={formPayload?.proxyConfig?.auth?.password}
                        name="proxyAuthPassword"
                        onChange={handleFormPayload}
                      />
                    </div>
                  )}
                  <InputLabel
                    title="Not"
                    value={formPayload?.note}
                    multiline
                    name="note"
                    onChange={handleFormPayload}
                  />

                  <ToggleSwitch
                    checked={formPayload?.isMessageSent}
                    label="Mesaj Gönderim"
                    onChange={(value: boolean) => {
                      setFormPayload({
                        ...formPayload,
                        ['isMessageSent']: value,
                      });
                    }}
                    name="isMessageSent"
                  />

                  <div className="flex justify-between">
                    <Button
                      isProcessing={formLoading}
                      disabled={formLoading}
                      type="submit"
                      color="blue"
                    >
                      Kaydet
                    </Button>

                    {!deviceInfo?.isExternal && (
                      <Button.Group>
                        <Button color="gray" disabled>
                          Aç
                        </Button>
                        <Button
                          color="gray"
                          onClick={deviceResetHandler}
                          isProcessing={resetLoading}
                          disabled={resetLoading}
                        >
                          Restart
                        </Button>
                        <Button
                          color="gray"
                          type="button"
                          onClick={deviceDeleteHandler}
                          isProcessing={deleteLoading}
                          disabled={true}
                        >
                          Sil
                        </Button>
                      </Button.Group>
                    )}
                  </div>
                </div>
              </form>
            </CardContainer>
            <CardContainer title="Gönderilen Mesajlar">
              <DeviceMessages id={deviceInfo?._id || ''} />
            </CardContainer>
            <CardContainer title="Logs">
              <DeviceLogs id={deviceInfo?._id || ''} />
            </CardContainer>
          </div>
        </div>
      )}
    </>
  );
};
