import { createContext, useContext, ReactNode } from 'react';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

import { useLocalStorage } from './useLocalStorage';

export interface IUser {
  id: string;
  name: string;
  username: string;
  email: string;
}

interface IGlobalProvider {
  user: any;
  login: (data: IUser, token: string) => void;
  logout: () => void;
  token: {
    token: string;
  };
  showAlert: (config: SweetAlertOptions) => Promise<SweetAlertResult>;
}

const initialValue: IGlobalProvider = {
  user: {},
  login: () => {},
  logout: () => {},
  token: {
    token: '',
  },
  showAlert: (config: SweetAlertOptions) => Swal.fire(config),
};

const GlobalContext = createContext<IGlobalProvider>(initialValue);

type Props = {
  children: ReactNode;
};

export function GlobalProvider({ children }: Props) {
  const [user, setUser] = useLocalStorage('user', null);
  const [token, setToken] = useLocalStorage('token', null);

  const login = async (data: IUser, token: string) => {
    setUser(data);
    setToken({
      token,
    });
  };

  const logout = async () => {
    setUser(null);
    setToken(null);
  };

  const showAlert = (config: SweetAlertOptions) => {
    return Swal.fire(config);
  };

  const value: IGlobalProvider = {
    user,
    login,
    logout,
    token,
    showAlert,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export function useGlobal() {
  return useContext(GlobalContext);
}
