import React, { FC, useEffect, useState } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { Button, Spinner, Table } from 'flowbite-react';
import { deleteUser, getUsers, saveUser } from '../../services';
import { CardContainer } from '../../layout';

const initialFormErrors = {
  name: false,
  username: false,
  email: false,
  password: false,
};

const initialPayload = {
  name: '',
  username: '',
  email: '',
  password: '',
};

export const UserPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<any[]>();
  const [payload, setPayload] = useState<any>(initialPayload);
  const [formLoading, setFormloading] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>(
    initialFormErrors,
  );

  const fetchUsers = async () => {
    try {
      const response: any = await getUsers();

      setUsers(response.data);
    } catch (error) {
      console.error('fetchUsers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPayload((prevPayload: any) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const validateFields = () => {
    const errors: any = {};

    for (const key in payload) {
      errors[key] = !payload[key];
    }

    setFormErrors(errors);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields();

    try {
      setFormloading(true);

      await saveUser(payload)
        .then(() => {
          setPayload(initialPayload);
        })
        .finally(() => {
          setFormloading(false);
        });
      await fetchUsers();
    } catch (error) {
      console.error('saveUser: ', error);
    }
  };

  const userDeleteHandler = async (id: string) => {
    const confirmBtn = confirm('Silmek istediğinize emin misini?');
    if (confirmBtn) {
      deleteUser(id).then(() => {
        fetchUsers();
      });
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <CardContainer title="Kullanıcılar">
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <Table striped hoverable>
          <Table.Head>
            <Table.HeadCell>Ad Soyad</Table.HeadCell>
            <Table.HeadCell>E-mail</Table.HeadCell>
            <Table.HeadCell>Username</Table.HeadCell>
            <Table.HeadCell></Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {users?.map((user, index) => {
              const { name, username, email, _id: id } = user;
              return (
                <Table.Row key={id}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>{username}</Table.Cell>
                  <Table.Cell className="flex justify-end">
                    <Button.Group>
                      <Button color="gray" size="sm" disabled>
                        <HiPencil />
                      </Button>
                      <Button
                        color="gray"
                        size="sm"
                        onClick={() => userDeleteHandler(id)}
                      >
                        <HiTrash />
                      </Button>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </CardContainer>
  );
};
