export const sites: { id: number; name: string }[] = [
  { id: 1, name: 'Betkanyon' },
  { id: 2, name: 'Winxbet' },
  { id: 3, name: 'Bahisal' },
  // { id: 4, name: 'Winxbet(BR)' },
  // { id: 5, name: 'Unibahis' },
  { id: 7, name: 'Betloto' },
];

export const GetSite = (siteId: number): string => {
  const site = sites.find((s) => s.id === siteId);
  return site ? site.name : '';
};
