import React, { FC, useState, useCallback, useEffect } from 'react';
import { Button, FileInput, Label, Spinner, TextInput } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import { CardContainer } from '../../layout';
import { getSiteById, updateSite } from '../../services';
import { useGlobal } from '../../hooks/useGlobal';
import { Msg } from '../../consts';

interface ISiteData {
  _id: string;
  name: string;
  displayName: string;
  aboutText: string;
  profilePicture: string;
}

export const SiteDetail: FC = () => {
  const { showAlert } = useGlobal();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [data, setData] = useState<ISiteData>({
    _id: '',
    name: '',
    displayName: '',
    aboutText: '',
    profilePicture: '',
  });

  const fetchData = useCallback(async () => {
    if (!id) return;

    try {
      const response: any = await getSiteById(id);
      setData(response.data);
    } catch (error) {
      console.error('getSiteById error:', error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!data?._id) return;

    setFormLoading(true);

    try {
      await updateSite(data?._id, data);

      await fetchData();

      showAlert({
        text: Msg.Success,
        icon: 'success',
      });
    } catch (error) {
      showAlert({
        text: Msg.Error,
        icon: 'error',
      });

      console.error('updateSite error:', error);
    } finally {
      setFormLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CardContainer title="Site Düzenle">
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="block">
              <Label htmlFor="name" value="Başlık" />
            </div>
            <TextInput
              id="name"
              type="text"
              required
              value={data.name}
              onChange={(e) => {
                setData((p: any) => ({
                  ...p,
                  name: e.target.value,
                }));
              }}
            />
          </div>
          <div className="mb-4">
            <div className="block">
              <Label htmlFor="displayName" value="Whatsapp İsim" />
            </div>
            <TextInput
              id="displayName"
              type="text"
              value={data.displayName}
              onChange={(e) => {
                setData((p: any) => ({
                  ...p,
                  displayName: e.target.value,
                }));
              }}
            />
          </div>
          <div className="mb-4">
            <div className="block">
              <Label htmlFor="aboutText" value="Whatsapp Durum" />
            </div>
            <TextInput
              id="aboutText"
              type="text"
              value={data.aboutText}
              onChange={(e) => {
                setData((p: any) => ({
                  ...p,
                  aboutText: e.target.value,
                }));
              }}
            />
          </div>
          <div className="mb-4">
            <div className="block">
              <Label htmlFor="profilePicture" value="Logo" />
            </div>
            <TextInput
              id="profilePicture"
              type="text"
              value={data.profilePicture}
              onChange={(e) => {
                setData((p: any) => ({
                  ...p,
                  profilePicture: e.target.value,
                }));
              }}
            />
          </div>
          <Button
            type="submit"
            color="blue"
            isProcessing={formLoading}
            disabled={formLoading}
          >
            Kaydet
          </Button>
        </form>
      )}
    </CardContainer>
  );
};
