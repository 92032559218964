import { FC } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ListGroup } from 'flowbite-react';
import { useGlobal } from '../hooks/useGlobal';
import { Header, Footer } from '../components';

export const SettingsLayout: FC = () => {
  const { user } = useGlobal();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleNavigation = (path: string) => {
    navigate(path, { replace: true });
  };

  return (
    <>
      <Header user={user} />
      <div className="flex pt-16">
        <div className="relative w-full max-w-screen-xl mx-auto h-full">
          <main className="flex gap-4 my-2">
            <div className="w-[300px]">
              <ListGroup>
                <ListGroup.Item
                  onClick={() => handleNavigation('/settings')}
                  active={pathname == '/settings'}
                >
                  Kullanıcılar
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => handleNavigation('/message-template')}
                  active={pathname == '/message-template'}
                >
                  Mesaj Taskları
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => handleNavigation('/site')}
                  active={pathname == '/site'}
                >
                  Siteler
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="w-full">
              <Outlet />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};
