import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

// WebSocket context type definition
interface WebSocketContextType {
  socket: WebSocket | null;
  sendMessage: (message: string) => void;
  latestMessage: string | null;
}

// Create WebSocket context
const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined,
);

type Props = {
  children: ReactNode;
};

// WebSocket provider component
export const WebSocketProvider = ({ children }: Props) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [latestMessage, setLatestMessage] = useState<string | null>(null);

  useEffect(() => {
    const ws = new WebSocket('wss://api.wpinvite.com');

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onclose = () => {
      console.log('WebSocket disconnected');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error', error);
    };

    ws.onmessage = (event) => {
      // Handle different data types
      if (event.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          const text = reader.result as string;
          setLatestMessage(JSON.parse(text));
        };
        reader.readAsText(event.data);
      } else if (typeof event.data === 'string') {
        setLatestMessage(JSON.parse(event.data));
      } else {
        console.warn('Unsupported message type:', typeof event.data);
      }
    };

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []);

  const sendMessage = (message: string) => {
    if (socket) {
      socket.send(message);
    } else {
      console.error('WebSocket is not connected');
    }
  };

  return (
    <WebSocketContext.Provider value={{ socket, sendMessage, latestMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook to use WebSocket context
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
