import { FC, useCallback, useEffect, useState } from 'react';
import { Spinner, Table } from 'flowbite-react';
import { getDeviceLogsById } from '../../services';
import { formatDate } from '../../utils';

interface IDeviceLogs {
  id: string;
}

export const DeviceLogs: FC<IDeviceLogs> = ({ id }: IDeviceLogs) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [logs, setLogs] = useState<any[]>([]);
  const [logStatus, setLogStatus] = useState<string>('');

  const renderLogData = (data: any) => {
    setLogs(data.deviceLogs);

    const startDate = formatDate(data.startOfDay);
    const endDate = formatDate(data.endOfDay);

    setLogStatus(`${startDate} ile ${endDate} tarihleri arasında toplam ${data.deviceLogsCount} adet
    log bulunmaktadır.`);
  };

  const fetchDeviceLogs = useCallback(async () => {
    try {
      const res: any = await getDeviceLogsById(id);
      renderLogData(res.data);
    } catch (error) {
      console.error('fetchDeviceLogs: ', error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchDeviceLogs();
  }, [fetchDeviceLogs]);

  return (
    <>
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell></Table.HeadCell>
              <Table.HeadCell>Tip</Table.HeadCell>
              <Table.HeadCell></Table.HeadCell>
              <Table.HeadCell>İlk Kayıt</Table.HeadCell>
              <Table.HeadCell>Son Kayıt</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {logs.map((log, index) => {
                const {
                  _id,
                  eventTypeCount,
                  eventType,
                  description,
                  firstCreatedAt,
                  lastCreatedAt,
                } = log;
                return (
                  <Table.Row key={_id}>
                    <Table.Cell>{eventTypeCount}</Table.Cell>
                    <Table.Cell>{eventType}</Table.Cell>
                    <Table.Cell>{description}</Table.Cell>
                    <Table.Cell>
                      {eventType === 'MESSAGE' ||
                      eventType === 'UNPAIRED' ||
                      eventType === 'CHANGE_STATE'
                        ? formatDate(firstCreatedAt, 'DD.MM.YYYY HH:mm:ss')
                        : null}
                    </Table.Cell>
                    <Table.Cell>
                      {formatDate(lastCreatedAt, 'DD.MM.YYYY HH:mm:ss')}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <div className="mt-4 text-gray-500 text-sm text-right">
            {logStatus}
          </div>
        </>
      )}
    </>
  );
};
