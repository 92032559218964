import { RouteObject, useRoutes } from 'react-router-dom';
import { RootLayout, SettingsLayout } from '../layout';
import {
  DashboardPage,
  DeviceDetail,
  LoginPage,
  MessageTemplateDetail,
  MessageTemplateMain,
  NumbersMain,
  SiteDetail,
  SiteMain,
  StatisticsMain,
  UserPage,
} from '../containers';

const Router = () => {
  const routes: RouteObject[] = [
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {
          path: '/',
          index: true,
          element: <DashboardPage />,
        },
        {
          path: '/device/:id',
          element: <DeviceDetail />,
        },
        {
          path: '/statistics',
          element: <StatisticsMain />,
        },
        {
          path: '/numbers',
          element: <NumbersMain />,
        },
      ],
    },
    {
      element: <SettingsLayout />,
      children: [
        {
          index: true,
          path: '/settings',
          element: <UserPage />,
        },
        {
          path: '/message-template',
          element: <MessageTemplateMain />,
        },
        {
          path: '/message-template/:id',
          element: <MessageTemplateDetail />,
        },
        {
          path: '/site',
          element: <SiteMain />,
        },
        {
          path: '/site/:id',
          element: <SiteDetail />,
        },
      ],
    },
  ];

  return useRoutes(routes);
};

export default Router;
