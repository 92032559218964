import React, { FC } from 'react';
import { Input } from '../Input';

interface IInputLabel extends React.HTMLProps<HTMLInputElement> {
  containerClass?: string;
  labelClass?: string;
  error?: string | null;
  title: string;
  multiline?: boolean;
}

export const InputLabel: FC<IInputLabel> = ({
  containerClass,
  labelClass,
  error,
  title,
  multiline = false,
  ...props
}: IInputLabel) => {
  const inputClass =
    'shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5';

  return (
    <div className={containerClass}>
      <label
        className={`block mb-2 text-sm font-medium text-gray-900 ${labelClass}`}
      >
        {title}
      </label>
      <Input
        className={inputClass}
        htmlType={multiline ? 'textarea' : 'input'}
        {...props}
      />
      {error && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{error}</p>
      )}
    </div>
  );
};
